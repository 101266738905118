<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card outlined elevation="0">
      <v-card-title class="headline">
        {{$t('erp.lang_roomPlanerEditRoom')}}
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row no-gutters justify="center">
            <v-col lg="8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="roomName"
                                :label="$t('erp.lang_room')"
                                autocomplete="off"
                                :rules="[rules.required]"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="roomWidth"
                                :label="$t('erp.lang_roomWidth')"
                                autocomplete="off"
                                required
                                :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="roomTime"
                                :label="$t('erp.lang_seatingTime')"
                                autocomplete="off"
                                required type="number" :min="1"
                                :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="tableNameFontSize"
                                :label="$t('settings.lang_fontsizeForTableName')"
                                autocomplete="off"
                                required type="number" :min="1"
                                :rules="[rules.numberRange(tableNameFontSize,10,42)]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-select 
                    outlined :items="filteredPrinters"
                    :label="$t('settings.lang_overrideInvoicePrinter')"
                    v-model="overrideInvoicePrinterId"
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <v-checkbox :label="$t('generic.lang_dontAskForParties')" v-model="dontAskForParty"/>
                </v-col>
                <v-col cols="12">
                  <imagecropper ref="imageCropper" v-model="bgImage"  />
                </v-col>
              </v-row>
              <v-row no-gutters justify="end">

                <v-btn color="error"
                       x-large
                       @click="deleteData()"
                >
                  {{ $t('generic.lang_delete') }}
                </v-btn>
                <v-btn color="primary"
                       :loading="loading"
                       class="ml-1"
                       x-large
                       @click="updateRoom()"
                       :disabled="!valid"
                >
                  {{$t('generic.lang_edit') }}
                </v-btn>

              </v-row>
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns"
import Imagecropper from "@/components/common/imagecropper";
import {ENDPOINTS} from "../../../../config";
import validation from "../../../../mixins/validation/validation";
import { mapState } from "vuex";

export default {
  name: "RoomEditComponent",
  components:{Imagecropper},
  mixins:[mixin,validation],
  data(){
    return{
      valid:true,
      loading:true,
      roomName:"",
      roomWidth:'',
      roomTime:0,
      tableNameFontSize:16,
      empty:null,
      dontAskForParty:null,
      overrideInvoicePrinterId:null,
      id:null,
      bgImage:null,
    }
  },
  computed:{
    ...mapState(['printer']),
    filteredPrinters() {
      return [{
        text: this.$t('generic.lang_none'),
        value: null,
      },...this.printer.printers.filter((printer) => printer.type === 1).map((printer) => {
        return {
          text: printer.name || 'no name',
          value: printer.id,
        };
      })]
    }
  },
  methods:{
    deleteData(){
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_roomPlanerDeleteRoomSub'),
        text: this.$t('erp.lang_roomPlanerDeleteRoom'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let ids=[];
          ids.push(this.id);
          this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.DELETE, {
            roomIDs: ids,
          }).then((res) => {
            if (res.status===200) {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_roomPlanerSuccesfullRoomDelete'),
                color: "success"
              });

            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
            this.$router.go(-1);
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    getData(){
      this.loading=true;

      this.id=parseInt(this.$route.params.id);
      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.GET,{roomID:this.id})
          .then(res=>{
            if(res.status===200){
              this.roomName=res.data.formfillData.textFields.roomName;
              this.roomWidth=res.data.formfillData.textFields.roomWidth;
              this.roomTime=res.data.formfillData.textFields.roomTime;
              this.bgImage=res.data.formfillData.textFields.bgImage;
              this.dontAskForParty=res.data.formfillData.textFields.dontAskForParty===1;
              this.overrideInvoicePrinterId=res.data.formfillData.textFields.overrideInvoicePrinterId;
              this.tableNameFontSize=res.data.formfillData.textFields.tableNameFontSize?res.data.formfillData.textFields.tableNameFontSize:16;
            }else{
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_unknownError'),
                color: "error"
              });
            }

          }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_error'),
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      });

    },
    updateRoom(){
      if(this.$refs.form.validate()){
        this.loading = true;
        if (this.$refs.hasOwnProperty("imageCropper"))
          this.$refs.imageCropper.cropImage();

        this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.UPDATE,{
          roomID:this.id,
          room_name:this.roomName,
          room_width:parseInt(this.roomWidth),
          roomTime:parseInt(this.roomTime),
          dontAskForParty:(this.dontAskForParty)?1:0,
          bgImage:this.bgImage,
          tableNameFontSize:this.tableNameFontSize,
          overrideInvoicePrinterId:this.overrideInvoicePrinterId,
        }).then(res=>{
          if(res.status===200){
            Events.$emit("showSnackbar", {
              message: this.$t('erp.lang_roomPlanerSucessfullAlteredRoom'),
              color: "success"
            });

          }else{
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_unknownError'),
              color: "error"
            });
          }
          this.$router.go(-1);
        }).catch(err=>{

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_error'),
            color: "error"
          });
        }).finally(()=>{
          this.loading=false;
        });
      }
    },
  },
  mounted() {
    this.id=parseInt(this.$route.params.id);
    this.getData()
  }
}
</script>

<style scoped>

</style>
